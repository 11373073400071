const initialState = {
    loading: false,
    isExist: null,
    getAddress: null,
    municipalityData: null,
};
export default function emailExistReducer(state = initialState, action) {
    switch (action.type) {
        case "GET_EXIST_RESPONSE":
            return {
                ...state,
                isExist: action.payload,
            };
        case "GET_CITY_RESPONSE":
            return {
                ...state,
                getAddress: action.payload,
            };
        case "GET_MUNICIPALITY_RESPONSE":
            return {
                ...state,
                municipalityData: action.payload,
            };
        default:
            return { ...state };
    }
}
