import qs from "qs";
import Repository from "./Repository";
const NEW_LEAD = "/leads";

export default {
    createNewLead(payload) {
        console.log("payload", payload, qs.stringify(payload));
        return Repository.post(`${NEW_LEAD}`, qs.stringify(payload));
    },
};
