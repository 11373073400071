import Header from "../components/Header";

const Main = (props) => {
    return (
        <>
            {/* <header className="text-site-orange bg-site-secondary">
                Main Header
            </header> */}
            {/* <Header /> */}
            {props.children}
            {/* <footer>Main Footer</footer> */}
        </>
    );
};

export default Main;
