// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import emailExistReducer from "./emailExistReducer";
import leadReducer from "./leadReducer";

export let rootReducer = combineReducers({
    authUser: authUserReducer,
    lead: leadReducer,
    emailsExist: emailExistReducer,
});

export default rootReducer;
