// createLead

import { RepositoryFactory } from "../../repository/getAddress/RepositoryFactory";
var LeadRepo = RepositoryFactory.get("getCity");

export const getCityByPostCode = (payload) => async (dispatch) => {
    try {
        let { data } = await LeadRepo.getCityByPostCode(payload);
        console.log(data);
        if (data) {
            dispatch({
                type: "GET_CITY_RESPONSE",
                payload: data,
            });
            dispatch({
                type: "STREET_ERROR",
                payload: { error: false },
            });
        }
    } catch (e) {
        console.log(e);
        dispatch({
            type: "STREET_ERROR",
            payload: { error: true },
        });
        dispatch({
            type: "GET_CITY_RESPONSE",
            payload: null,
        });
        // alert(e.response.data.error);
    }
};
