import axios from "axios";
const baseDomain = "https://services.bezwaarmaker.nl/website-api";

const baseURL = `${baseDomain}`;
let axiosObj;
axiosObj = axios.create({
    baseURL,
    headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 9537f407-7388-11ec-afea-42010a479003",
    },
});

export default axiosObj;
