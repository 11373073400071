import axios from "axios";
const baseDomain = "https://api.pro6pp.nl/v2/autocomplete/nl";

const baseURL = `${baseDomain}`;
let axiosObj;
axiosObj = axios.create({
    baseURL,
    headers: { Authorization: "Bearer " },
});

export default axiosObj;
