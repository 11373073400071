// createLead

import { RepositoryFactory } from "../../repository/getMunicipalityID/RepositoryFactory";
var LeadRepo = RepositoryFactory.get("getMunicipalityID");

export const getMunicipalityIDByPostCode = (payload) => async (dispatch) => {
    try {
        let { data } = await LeadRepo.getMunicipalityIDByPostCode(payload);
        if (data) {
            dispatch({
                type: "GET_MUNICIPALITY_RESPONSE",
                payload: data,
            });
        }
    } catch (e) {
        dispatch({
            type: "GET_MUNICIPALITY_RESPONSE",
            payload: null,
        });
        // alert(e.response.data.error);
    }
};
