import firebase from "firebase/app";
// import firebase from 'firebase';
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBwiMA4HScwhYjDRMlcoGzmjKofnuWlXiY",
    authDomain: "republish-react.firebaseapp.com",
    databaseURL: "https://republish-react-default-rtdb.firebaseio.com",
    projectId: "republish-react",
    storageBucket: "republish-react.appspot.com",
    messagingSenderId: "249306848505",
    appId: "1:249306848505:web:f1fc4666522206c9de5628",
    measurementId: "G-H87KB5VR5X",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const storage = firebase.storage();
export default firebase;
