// createLead

import { RepositoryFactory } from "../../repository/addressExists/RepositoryFactory";
var LeadRepo = RepositoryFactory.get("addressAndEmailExist");

export const checkEmailAndAddressExist = (payload) => async (dispatch) => {
    // await dispatch(createLoader(true));
    try {
        let { data } = await LeadRepo.checkEmailAndAddressExist(payload);
        if (data) {
            dispatch({
                type: "GET_EXIST_RESPONSE",
                payload: data,
            });
            // history.push("/thankyou");
            // await dispatch(createLoader(false));
        } else {
            // await dispatch(createLoader(false));
        }
    } catch (e) {
        alert(e.response.data.error);
        // console.log(e, 'e');
        // await dispatch(createLoader(false));
    }
};
// export const createLoader = (val) => async (dispatch) => {
//     dispatch({
//         type: "CREATE_LOADER",
//         payload: val,
//     });
// };
