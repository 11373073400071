// createLead

import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { Link, useHistory } from "react-router-dom";
var LeadRepo = RepositoryFactory.get("newLead");
let name = "Bad Request - Validation error for 'phone_number'";
export const createNewLead =
    (payload, questAns2, history) => async (dispatch) => {
        await dispatch(createLoader(true));
        if (payload.bootCheck) {
            dispatch({
                type: "CREATE_RESPONSE",
                payload: "data",
            });
            questAns2 == "Ja"
                ? history.push("/thankyou")
                : history.push("/thankyou-nee");
            await dispatch(createLoader(false));
        } else {
            try {
                let { data } = await LeadRepo.createNewLead(payload);
                console.log("apiResponse=====>", data);
                if (data) {
                    dispatch({
                        type: "CREATE_RESPONSE",
                        payload: data,
                    });
                    questAns2 == "Ja"
                        ? history.push("/thankyou")
                        : history.push("/thankyou-nee");
                    await dispatch(createLoader(false));
                } else {
                    await dispatch(createLoader(false));
                }
            } catch (e) {
                console.log(e.response.data.error);
                if (e.response.data.error == "Conflict - duplicate") {
                    dispatch({
                        type: "CREATE_RESPONSE",
                        payload: "data",
                    });
                    questAns2 == "Ja"
                        ? history.push("/thankyou")
                        : history.push("/thankyou-nee");
                } else {
                    let error = e.response.data.error
                        .match(/'([^']+)'/)[0]
                        .slice(1, -1);
                    dispatch({
                        type: "ERROR_RESPONSE",
                        payload: { [error]: true },
                    });
                }
                await dispatch(createLoader(false));
            }
        }
    };
export const errorResponse = () => async (dispatch) => {
    dispatch({
        type: "ERROR_RESPONSE",
        payload: null,
    });
};
export const createLoader = (val) => async (dispatch) => {
    dispatch({
        type: "CREATE_LOADER",
        payload: val,
    });
};
