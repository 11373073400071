import axios from "axios";
const baseDomain = "https://leadgen.republish.nl/api/sponsors/2179";

const baseURL = `${baseDomain}`;
let axiosObj;
axiosObj = axios.create({
    baseURL,
    headers: {
        // "Access-Control-Allow-Origin": baseURL,
        Authorization:
            "Basic MTg1OmFiODIyMWQ0YTMxNzBkODk1NDI4ODA0NTlhYmY3OTgxN2FlMzY3YzI=",
    },
});

export default axiosObj;
