import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";
import { useSelector } from "react-redux";

const Header = () => {
    const { create_response } = useSelector((state) => state.lead);
    useEffect(() => {
        console.log("call");
        if (create_response?.profile) {
            setTimeout(() => {
                let useScript = document.createElement("script");
                useScript.innerHTML = !(function (f, b, e, v, n, t, s) {
                    if (f.fbq) return;
                    n = f.fbq = function () {
                        n.callMethod
                            ? n.callMethod.apply(n, arguments)
                            : n.queue.push(arguments);
                    };
                    if (!f._fbq) f._fbq = n;
                    n.push = n;
                    n.loaded = !0;
                    n.version = "2.0";
                    n.queue = [];
                    t = b.createElement(e);
                    t.async = !0;
                    t.src = v;
                    s = b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t, s);
                })(
                    window,
                    document,
                    "script",
                    "https://connect.facebook.net/en_US/fbevents.js"
                );
                window.fbq("init", "969816563708997");
                window.fbq("track", "PageView");
                window.fbq("track", "Lead");
                document.head.appendChild(useScript);
            }, 4000);
        } else {
            setTimeout(() => {
                let useScript = document.createElement("script");
                useScript.innerHTML = !(function (f, b, e, v, n, t, s) {
                    if (f.fbq) return;
                    n = f.fbq = function () {
                        n.callMethod
                            ? n.callMethod.apply(n, arguments)
                            : n.queue.push(arguments);
                    };
                    if (!f._fbq) f._fbq = n;
                    n.push = n;
                    n.loaded = !0;
                    n.version = "2.0";
                    n.queue = [];
                    t = b.createElement(e);
                    t.async = !0;
                    t.src = v;
                    s = b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t, s);
                })(
                    window,
                    document,
                    "script",
                    "https://connect.facebook.net/en_US/fbevents.js"
                );
                window.fbq("init", "969816563708997");
                window.fbq("track", "PageView");
                document.head.appendChild(useScript);
            }, 4000);
        }
    }, []);
    return (
        <div className="d-flex justify-content-between align-items-center HeaderMain">
            <noscript>
                <img
                    height="1"
                    width="1"
                    className="d-none"
                    src="https://www.facebook.com/tr?id=969816563708997&ev=PageView&noscript=1"
                />
            </noscript>
            <div className="pt-3 d-flex align-items-center">
                <div>
                    <Link to="/">
                        <img src={logo} height="60px" />
                    </Link>
                </div>
                <div className="ml-2">
                    <p className="logoText mb-0">Besparen</p>
                    <p className="logoText1 mb-0">
                        op <span className="logoText2">woz</span>
                    </p>
                </div>
            </div>
            <div>
                <p className="mb-0 headerRightText">
                    {/* Betaal niet te veel belasting. */}
                </p>
            </div>
        </div>
    );
};

export default Header;
