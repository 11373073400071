import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, Container } from "reactstrap";
import Header from "../components/Header";
const ThankYou = () => {
    const { create_response } = useSelector((state) => state.lead);

    return (
        <div className="thankYouMain">
            <Container>
                <Header />
                <div className="container d-flex justify-content-center align-items-center py-5">
                    <Card className="px-3 px-md-5 py-3 w-100">
                        <p className="text-center text-dark thanksText mb-0">
                            Bedankt voor je aanvraag. Wij nemen zo spoedig
                            mogelijk contact met je op.
                        </p>
                        <span className="text-center confirmation-modal__description"></span>
                    </Card>
                    {create_response?.profile?.id ? (
                        <img
                            referrerpolicy="no-referrer-when-downgrade"
                            src={`https://republish.besparenopwoz.nl/m/5596/8026e572aa79/?event=6541&unique_conversion_id=${create_response?.profile.id}`}
                            style={{
                                width: "1px",
                                height: "1px",
                                border: "0px",
                            }}
                        />
                    ) : null}
                </div>
            </Container>
        </div>
    );
};

export default ThankYou;
