import firebase from "../../config/firebase";

export const savedMissedMun = (data) => () => {
    let newData = {
        ...data,
        created_at: firebase.firestore.Timestamp.now(),
    };
    firebase
        .firestore()
        .collection("wozu_missed_mun")
        .add(newData)
        .then(() => {
            console.log("addData");
        });
};
