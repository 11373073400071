import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    Col,
    Form,
    Input,
    Container,
    Row,
    UncontrolledTooltip,
    Spinner,
    Label,
    FormFeedback,
} from "reactstrap";
import arrowRight from "../assets/img/arrowRight.png";
import { useDispatch, useSelector } from "react-redux";
import { createNewLead, errorResponse } from "../store/actions/leadAction";
import { Link, useHistory } from "react-router-dom";
import arrow from "../assets/img/arrow.png";
import star from "../assets/img/star.png";
import tooltip1 from "../assets/img/tooltip1.jpg";
import tooltip2 from "../assets/img/tooltip2.png";
import tooltip3 from "../assets/img/tooltip3.jpg";
import tooltip4 from "../assets/img/tooltip4.png";
import Header from "./Header";
import SignaturePad from "react-signature-pad";
import { checkEmailAndAddressExist } from "../store/actions/addressAndEmailAction";
import { getCityByPostCode } from "../store/actions/getCityByPostcodeAction";
import { getMunicipalityIDByPostCode } from "../store/actions/getMunicipalityAction";
import moment from "moment/moment";
import { savedMissedMun } from "../store/actions/savedMissedMun";

const RegForm = () => {
    const { isExist, getAddress, municipalityData } = useSelector(
        (state) => state.emailsExist
    );
    const { error_response, street_error } = useSelector((state) => state.lead);
    const signaturePad = useRef(null);
    const [isActiveTab, setIsActiveTab] = useState(1);
    const [bootCheck, setBootCheck] = useState(false);
    const [questAns, setQuestAns] = useState();
    const [questAns2, setQuestAns2] = useState("Ja");
    const [postCode, setPostCode] = useState("");
    const [houseNo, setHouseNo] = useState("");
    const [toev, setToev] = useState("");
    const [address, setAddress] = useState("");
    const [municipalityID, setMunicipalityID] = useState("");
    const [addressExist, setAddressExist] = useState(false);
    const [emailExist, setEmailExist] = useState(false);
    const [skipStep, setSkipStep] = useState(false);
    const [street, setStreet] = useState("");
    const [place, setPlace] = useState("");
    const [propertyType, setPropertyType] = useState("");
    // const [livingSituation, setLivingSituation] = useState("");
    const [email, setEmail] = useState("");
    const [description, setDescription] = useState("");
    const [phone, setPhone] = useState("");
    const [claimNumber, setClaimNumber] = useState("");
    const [initials, setInitials] = useState("");
    const [infix, setInfix] = useState("");
    const [surname, setSurname] = useState("");
    const [minDate, setMinDate] = useState(false);
    const [drawingDate, setDrawingDate] = useState(
        new Date().toISOString().slice(0, 10)
        // "2022-01-01"
    );
    const [wozValue, setWozValue] = useState("");
    const [refrenceDate, setRefrenceDate] = useState("3748");
    const [signature, setSignature] = useState("true");
    const [siteSubId, setSiteSubId] = useState("");
    const [publisherId, setPublisherId] = useState("");

    const { create_response, loading } = useSelector((state) => state.lead);
    // const signature1 = signaturePad.current.toDataURL();

    const history = useHistory();
    const dispatch = useDispatch("");

    const handleSkipStepChange = (value) => {
        setSkipStep(value);
    };
    useEffect(() => {
        if (
            error_response?.phone_number ||
            error_response?.email ||
            error_response?.lastname
        ) {
            setIsActiveTab(4);
        } else if (
            error_response?.street ||
            error_response?.zip ||
            error_response?.house_number
        ) {
            setIsActiveTab(1);
        }
    }, [error_response]);
    useEffect(() => {
        setSiteSubId(history?.location?.state?.siteSubId);
        setPublisherId(history?.location?.state?.publisherId);
    }, []);

    const isLessThan42Days = (selectedDate) => {
        // // Convert the input date to a Moment.js object
        // const momentDate = moment(selectedDate);

        // // Calculate the difference between the input date and today's date in days
        // const diffInDays = moment().diff(momentDate, "days");

        // // Check if the difference is less than 42 days
        // return diffInDays > 42;
        const momentDate = moment(selectedDate);

        // Check if the selected date is after today's date
        if (momentDate.isAfter(moment(), "day")) {
            return true; // Future date, return false
        }

        // If the selected date is not after today's date, it's either today or a past date
        return false;
    };
    const postCodeCheck = () => {
        let postalRejex = /^([0-9]{4}\s?[a-zA-Z]{2})$/;
        if (postCode != "") {
            if (postalRejex.test(postCode)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };
    const houseCheck = () => {
        let houseRejex = /[^0-9\s]+/;
        if (houseNo != "") {
            if (houseRejex.test(houseNo)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };
    const toevCheck = () => {
        let houseRejex = /^[a-z\s]+$/i;
        if (toev != "") {
            if (houseRejex.test(toev)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };
    const emailCheck = () => {
        let emailRejex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (email != "") {
            if (emailRejex.test(email)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };
    const phoneCheck = () => {
        let phoneRejex = /^0\d{9}$/;
        if (phone != "") {
            if (phoneRejex.test(phone)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };
    const claimNumberCheck = () => {
        let claimNumberRejex = /^\d+$/;
        if (claimNumber != "") {
            if (claimNumberRejex.test(claimNumber)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };
    const surNameCheck = () => {
        let nameRegex = /^([a-zA-Z ]){1,30}$/;
        if (surname != "") {
            if (nameRegex.test(surname)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };
    const wozValueCheck = () => {
        let wozValueRegex = /^(10[0-9]{1,}|[1-9][0-9]*(\.+)*0{3})$/;
        // /^(10[0-9]{1,}|[1-9][0-9]{1,}(\.0+)?|10000)$/;

        let stringWithoutDot = wozValue.replace(/\./g, "");
        if (wozValue != "") {
            if (
                wozValueRegex.test(wozValue) &&
                // stringWithoutDot.length > 5 &&
                stringWithoutDot.length <= 6
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };
    const handleSignatureInput = (val) => {
        setSignature(val);
    };

    const reffrenceArray = [
        { name: "1 januari 2023", value: "3745" },
        { name: "1 januari 2022", value: "3748" },
        { name: "1 januari 2021", value: "3751" },
        { name: "1 januari 2020", value: "3754" },
        { name: "1 januari 2019", value: "3757" },
        { name: "1 januari 2018", value: "3760" },
        { name: "1 januari 2017", value: "3763" },
        { name: "1 januari 2016", value: "3766" },
        { name: "1 januari 2015", value: "3769" },
        { name: "1 januari 2014", value: "3772" },
        { name: "1 januari 2013", value: "3775" },
    ];

    const getReference = (reference) => {
        let obj = reffrenceArray?.find((ref) => ref.value == reference);
        return obj;
    };

    useEffect(() => {
        // axios
        //     .get(
        //         `https://cors-platform.herokuapp.com/https://json.api-postcode.nl?postcode=${postCode}&number=${houseNo}`,
        //         {
        //             headers: {
        //                 "Content-Type": "application/json",
        //                 token: "652d0b86-9adc-4143-8c3e-00af15b80781",
        //             },
        //         }
        //     )
        //     .then((res) => {
        //         setAddress(res.data);
        //         setStreet(res.data.street);
        //         setPlace(res.data.city);
        //     });
        let obj = {
            postCode: postCode.replace(/\s/g, ""),
            houseNo: houseNo.replace(/\s/g, ""),
            toev: toev.replace(/\s/g, ""),
        };
        if (
            houseNo !== "" &&
            postCode !== "" &&
            !toevCheck() &&
            !houseCheck() &&
            !postCodeCheck()
        ) {
            dispatch(getCityByPostCode(obj));
            dispatch(getMunicipalityIDByPostCode(obj));
        }
    }, [houseNo, postCode, toev]);

    useEffect(() => {
        if (getAddress == null) {
            setAddress("");
            setStreet("");
            setPlace("");
        } else {
            setAddress(getAddress);
            setStreet(getAddress?.street);
            setPlace(getAddress?.settlement);
        }
    }, [getAddress]);
    useEffect(() => {
        if (municipalityData == null) {
            setMunicipalityID("999");
        } else {
            setMunicipalityID(municipalityData?.data.municipality?.id);
        }
    }, [municipalityData]);
    useEffect(() => {
        if (isExist?.data.address_exists == null) {
            setAddressExist(false);
        } else {
            setAddressExist(isExist?.data.address_exists);
        }
    }, [place]);
    useEffect(() => {
        if (isExist?.data.email_exists == null) {
            setEmailExist(false);
        } else {
            setEmailExist(isExist?.data.email_exists);
        }
    }, [email]);

    useEffect(() => {
        // if (postCode != "" && houseNo != "" && street != "" && place != "") {
        // axios
        //     .post(
        //         `https://services.bezwaarmaker.nl/website-api/objection-request/temp`,
        //         {
        //             zip_code: postCode,
        //             house_number: houseNo,
        //             street: street,
        //             city: place,
        //         },
        //         {
        //             headers: {
        //                 "Content-Type": "application/json",
        //                 Authorization:
        //                     "Bearer 9537f407-7388-11ec-afea-42010a479003",
        //             },
        //         }
        //     )
        //     .then((res) => {
        //         setAddressExist(res.data.data.address_exists);
        //     });
        let obj = {
            zip_code: postCode,
            house_number: houseNo,
            street: street,
            city: place,
        };
        dispatch(checkEmailAndAddressExist(obj));
        // }
    }, [place]);

    useEffect(() => {
        if (email.length > 5) {
            // axios
            //     .post(
            //         `https://services.bezwaarmaker.nl/website-api/objection-request/temp`,
            //         {
            //             email: email,
            //         },
            //         {
            //             headers: {
            //                 "Content-Type": "application/json",
            //                 Authorization:
            //                     "Bearer 9537f407-7388-11ec-afea-42010a479003",
            //             },
            //         }
            //     )
            //     .then((res) => {
            //         setEmailExist(res.data.data.email_exists);
            //     });
            let obj = {
                email: email,
            };
            dispatch(checkEmailAndAddressExist(obj));
        }
    }, [email]);

    const saveSignature = () => {
        const signature1 = signaturePad.current.toDataURL();
        return signature1;
    };
    const clearSignature = () => {
        signaturePad.current.clear();
    };

    const handleData = async (e) => {
        e.preventDefault();
        const text_answers = [];
        text_answers[1396] = wozValue.replace(/\./g, "");
        text_answers[1231] = claimNumber;
        text_answers[1393] = drawingDate;
        if (municipalityID == "999") {
            dispatch(savedMissedMun({ postCode: postCode, houseNo: houseNo }));
        }
        if (municipalityID != "") {
            text_answers[1430] = municipalityID;
        }
        if (questAns2 == "Ja") {
            text_answers[1427] = description;
        }
        if (questAns2 == "Nee") {
            text_answers[1396] = "";
            text_answers[1231] = "";
            text_answers[1393] = "";
            await setRefrenceDate("3827");
        }
        if (signature == "true") {
            text_answers[1411] = saveSignature();
        }
        if (questAns == "Nee") {
            let obj = {
                language: "nl_NL",
                firstname: initials,
                lastname: surname,
                email: email,
                phone_number: phone,
                zip: postCode,
                house_number: houseNo,
                answers: [
                    "3277",
                    "3730",
                    propertyType,
                    refrenceDate,
                    signature == "true" ? "3848" : "3851",
                ],
                text_answers: text_answers,
                site_custom_url: "https://besparenopwoz.nl/",
                site_custom_name: "woz_unbranded",
                site_subid: siteSubId,
                publisher_id: publisherId,
                street: street,
                city: place,
                bootCheck: bootCheck,
            };
            dispatch(createNewLead(obj, questAns2, history));
        } else {
            let obj = {
                language: "nl_NL",
                firstname: initials,
                lastname: surname,
                email: email,
                phone_number: phone,
                zip: postCode,
                house_number: houseNo,
                answers: [
                    "3277",
                    "3733",
                    propertyType,
                    refrenceDate,
                    signature == "true" ? "3848" : "3851",
                ],
                text_answers: text_answers,
                site_custom_url: "https://besparenopwoz.nl/",
                site_custom_name: "woz_unbranded",
                site_subid: siteSubId,
                publisher_id: publisherId,
                street: street,
                city: place,
                bootCheck: bootCheck,
            };
            dispatch(createNewLead(obj, questAns2, history));
        }
    };

    const handleQuestValue = (value) => {
        setQuestAns(value);
    };
    const handleQuest2Value = (value) => {
        setQuestAns2(value);
        setRefrenceDate("");
    };
    useEffect(() => {
        if (questAns2 == "Nee")
            setTimeout(() => {
                setRefrenceDate("3827");
                setIsActiveTab(4);
            }, 1000);
    }, [questAns2]);

    return (
        <div className="formOuter">
            <Container>
                <Header />
                <p className="formHeading py-4">Gratis bezwaar maken</p>
                <div className="mx-auto mainForm mb-2">
                    <Row className="d-flex justify-content-center align-items-center pt-0 pt-md-3 mx-2 mobileView">
                        <Col className="mt-3 mt-md-0">
                            <h5
                                className={
                                    isActiveTab == 1
                                        ? "text-site-secondary headingText activeTab"
                                        : "headingText"
                                }
                                // onClick={() => setIsActiveTab(1)}
                            >
                                1. Onroerend goed
                            </h5>
                        </Col>
                        <Col>
                            {" "}
                            <h5
                                className={
                                    isActiveTab == 2
                                        ? "text-site-secondary headingText activeTab"
                                        : "headingText"
                                }
                                // onClick={() => setIsActiveTab(2)}
                            >
                                2. Aanslaggegevens
                            </h5>
                        </Col>
                        <Col>
                            {" "}
                            <h5
                                className={
                                    isActiveTab == 3
                                        ? "text-site-secondary headingText activeTab"
                                        : "headingText"
                                }
                                // onClick={() => setIsActiveTab(3)}
                            >
                                3. Reden bezwaar
                            </h5>
                        </Col>
                        <Col>
                            {" "}
                            <h5
                                className={
                                    isActiveTab == 4
                                        ? "text-site-secondary headingText activeTab"
                                        : "headingText"
                                }
                                // onClick={() => setIsActiveTab(3)}
                            >
                                4. Contactgegevens
                            </h5>
                        </Col>
                        <Col>
                            {" "}
                            <h5
                                className={
                                    isActiveTab == 5
                                        ? "text-site-secondary headingText activeTab"
                                        : "headingText"
                                }
                                // onClick={() => setIsActiveTab(3)}
                            >
                                5. Afronden
                            </h5>
                        </Col>
                    </Row>
                    <hr className="bg-site-secondary w-100 borderStyling"></hr>
                    {isActiveTab == 1 ? (
                        <>
                            <Form
                                onSubmit={(evt) => {
                                    evt.preventDefault();
                                    setIsActiveTab(2);
                                }}
                            >
                                <Row className="mx-2">
                                    <Col md={7}>
                                        <h5 className="mb-3 formHeading2">
                                            {/* Adresgegevens van uw pand */}
                                            Adresgegevens{" "}
                                        </h5>
                                        <Row>
                                            <Col>
                                                <Label className="fontText mb-0 pb-2 px-md-2 px-0">
                                                    {/* <b>Type pand</b> */}
                                                    <b>Type woning</b>
                                                </Label>
                                                <Input
                                                    type="select"
                                                    bsSize="lg"
                                                    value={propertyType}
                                                    className="rounded-pill selectText"
                                                    id="exampleSelect"
                                                    onChange={(e) =>
                                                        setPropertyType(
                                                            e.target.value
                                                        )
                                                    }
                                                    required
                                                >
                                                    <option
                                                        disabled
                                                        selected
                                                        value=""
                                                    >
                                                        Type woning
                                                    </option>
                                                    <option value="3283">
                                                        Appartement
                                                    </option>
                                                    <option value="3286">
                                                        Hoekwoning
                                                    </option>
                                                    <option value="3709">
                                                        Tussenwoning
                                                    </option>
                                                    <option value="3712">
                                                        Twee onder een kap
                                                    </option>
                                                    <option value="3715">
                                                        Geschakelde woning
                                                    </option>
                                                    <option value="3718">
                                                        Vrijstaande woning
                                                    </option>
                                                    <option value="3721">
                                                        Bouwkavel
                                                    </option>
                                                    <option value="3724">
                                                        Garage of parkeerplaats
                                                    </option>
                                                    <option value="3727">
                                                        Overig
                                                    </option>
                                                </Input>
                                            </Col>
                                        </Row>
                                        <Row className="row-cols-lg-auto g-3 align-items-center mt-3">
                                            <Col lg={5}>
                                                <Label className="fontText mb-0 pb-2 px-md-2 px-0">
                                                    <b>Postcode</b>
                                                </Label>
                                                <Input
                                                    placeholder="Postcode"
                                                    type="text"
                                                    className="rounded-pill py-4"
                                                    value={postCode}
                                                    invalid={
                                                        postCodeCheck() ||
                                                        error_response?.zip ||
                                                        street_error?.error
                                                    }
                                                    onChange={(e) => {
                                                        setPostCode(
                                                            e.target.value
                                                        );
                                                        error_response?.zip &&
                                                            dispatch(
                                                                errorResponse()
                                                            );
                                                    }}
                                                    required
                                                />

                                                {error_response?.zip ||
                                                    (street_error?.error && (
                                                        <div className="text-center">
                                                            <small className="text-danger">
                                                                Postcode niet
                                                                correct
                                                            </small>
                                                        </div>
                                                    ))}
                                            </Col>
                                            <Col className="mt-3 mt-lg-0">
                                                <Label className="fontText mb-0 pb-2 px-md-2 px-0">
                                                    <b>Huisnr</b>
                                                </Label>

                                                <Input
                                                    id="Huisnr"
                                                    value={houseNo}
                                                    invalid={
                                                        houseCheck() ||
                                                        error_response?.house_number ||
                                                        street_error?.error
                                                    }
                                                    onChange={(e) => {
                                                        setHouseNo(
                                                            e.target.value
                                                        );
                                                        setStreet("");
                                                        setPlace("");
                                                        error_response?.house_number &&
                                                            dispatch(
                                                                errorResponse()
                                                            );
                                                    }}
                                                    placeholder="Huisnr"
                                                    type="Huisnr."
                                                    className="rounded-pill py-4"
                                                    required={
                                                        questAns == "Nee"
                                                            ? false
                                                            : true
                                                    }
                                                />
                                                {error_response?.house_number ||
                                                    (street_error?.error && (
                                                        <div className="text-center">
                                                            <small className="text-danger">
                                                                Huisnummer niet
                                                                correct
                                                            </small>
                                                        </div>
                                                    ))}
                                            </Col>
                                            <Col className="mt-3 mt-lg-0">
                                                <Label className="fontText mb-0 pb-2 px-md-2 px-0">
                                                    <b>Toev</b>
                                                </Label>

                                                <Input
                                                    id="Toev"
                                                    value={toev}
                                                    invalid={
                                                        toevCheck() ||
                                                        error_response?.house_number ||
                                                        street_error?.error
                                                    }
                                                    onChange={(e) => {
                                                        setToev(e.target.value);
                                                        setStreet("");
                                                        setPlace("");
                                                        error_response?.house_number &&
                                                            dispatch(
                                                                errorResponse()
                                                            );
                                                    }}
                                                    placeholder="Toev"
                                                    type="Toev."
                                                    className="rounded-pill py-4"
                                                />
                                                {error_response?.house_number ||
                                                    (street_error?.error && (
                                                        <div className="text-center">
                                                            <small className="text-danger">
                                                                Huisnummer niet
                                                                correct
                                                            </small>
                                                        </div>
                                                    ))}
                                            </Col>
                                            {/* <Col className="mt-3 mt-lg-0">
                                                <Input
                                                    id="Toev"
                                                    name="Toev"
                                                    placeholder="Toev."
                                                    type="Toev"
                                                    className="rounded-pill py-4"
                                                />
                                            </Col> */}
                                        </Row>
                                        <Row className="mt-3">
                                            <Col>
                                                <Label className="fontText mb-0 pb-2 px-md-2 px-0">
                                                    <b>Straat</b>
                                                </Label>
                                                <Input
                                                    id="Straat"
                                                    value={street}
                                                    invalid={
                                                        // emailCheck()
                                                        error_response?.street
                                                    }
                                                    onChange={(e) => {
                                                        setStreet(
                                                            e.target.value
                                                        );
                                                        error_response?.street &&
                                                            dispatch(
                                                                errorResponse()
                                                            );
                                                    }}
                                                    placeholder="Straat"
                                                    type="text"
                                                    className="rounded-pill py-4"
                                                    required
                                                />
                                                {error_response?.street && (
                                                    <div className="text-center">
                                                        <small className="text-danger">
                                                            Straatnaam is niet
                                                            correct
                                                        </small>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col>
                                                <Label className="fontText mb-0 pb-2 px-md-2 px-0">
                                                    <b>Plaats</b>
                                                </Label>
                                                <Input
                                                    id="Plaats"
                                                    value={place}
                                                    // invalid={emailCheck()}
                                                    onChange={(e) =>
                                                        setPlace(e.target.value)
                                                    }
                                                    placeholder="Plaats"
                                                    type="text"
                                                    className="rounded-pill py-4"
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={5} className="mt-3 mt-md-0">
                                        {/* {address ? (
                                            <>
                                                <div className="detailBox p-2 rounded">
                                                    <span>
                                                        {address?.street
                                                            ? address.street
                                                            : "Princess Beatrix"}
                                                    </span>{" "}
                                                    <span>
                                                        {address?.house_number
                                                            ? address.house_number
                                                            : "Avenue"}
                                                    </span>
                                                    <br />
                                                    <span>
                                                        {address?.postcode
                                                            ? address.postcode
                                                            : ""}
                                                    </span>{" "}
                                                    <span>
                                                        {" "}
                                                        {address?.city
                                                            ? address.city
                                                            : "The Hague"}
                                                    </span>
                                                    <br />
                                                    <span>
                                                        Municipality:
                                                    </span>{" "}
                                                    <span>
                                                        {address?.city
                                                            ? address.city
                                                            : "The Hague"}
                                                    </span>
                                                </div>
                                                <span className="fontText boxTextBtm">
                                                    Dit is het adres waartegen
                                                    gratis bezwaar wordt gemaakt
                                                </span>
                                            </>
                                        ) : (
                                            ""
                                        )} */}
                                        {/* <div className="d-flex justify-content-center mt-2 mb-3">
                                            <img src={arrow} height="73px" />
                                        </div>
                                        <div style={{ position: "relative" }}>
                                            <div className="formCircle">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <img
                                                            src={star}
                                                            height="60px"
                                                        />
                                                    </div>
                                                    <div className="ml-3 textcircle">
                                                        Al{" "}
                                                        <span className="shadowText2">
                                                            105.966
                                                        </span>{" "}
                                                        particulieren maakte ook
                                                        gratis bezwaar
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </Col>
                                </Row>
                                <Row className="mx-2 mt-4">
                                    <Col>
                                        {/* <h4 className="formBodyHeading">
                                            Onroerend goed
                                        </h4> */}
                                        <p className="mb-2 radioHeading">
                                            {/* Huurt u het pand? */}
                                            Uw woonsituatie
                                        </p>
                                        <div className="d-flex align-items-center mb-4">
                                            <div className="radioText mr-4">
                                                <input
                                                    required
                                                    id="Nee"
                                                    type="radio"
                                                    checked={questAns == "Nee"}
                                                    name="same"
                                                    className="mr-2 radioBtn pt-5"
                                                    value="Nee"
                                                    onChange={(e) =>
                                                        handleQuestValue(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                <label
                                                    className="m-0 p-0 pointer"
                                                    for="Nee"
                                                >
                                                    Koop
                                                </label>
                                            </div>
                                            <div className="radioText">
                                                <input
                                                    required
                                                    id="Ja"
                                                    type="radio"
                                                    checked={questAns == "Ja"}
                                                    name="same"
                                                    className="mr-2 radioBtn"
                                                    value="Ja"
                                                    onChange={(e) =>
                                                        handleQuestValue(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                <label
                                                    className="m-0 p-0 pointer"
                                                    for="Ja"
                                                >
                                                    Huur
                                                </label>
                                            </div>
                                        </div>
                                        <hr className="hrStyling" />
                                        {/* <p className="mb-2 radioHeading mt-2">
                                        Heeft u het pand recentelijk geerfd?
                                    </p>
                                    <div className="d-flex align-items-center mb-4">
                                        <div className="mr-5 radioText">
                                            <input
                                                type="radio"
                                                name="same1"
                                                className="mr-2 radioBtn"
                                            />
                                            <span>Nee</span>
                                        </div>
                                        <div className="radioText">
                                            <input
                                                type="radio"
                                                name="same1"
                                                className="mr-2 radioBtn"
                                            />
                                            <span>Ja</span>
                                        </div>
                                    </div>
                                    <hr className="hrStyling" /> */}
                                    </Col>
                                </Row>
                                <Row className="mx-2 py-2 align-items-center">
                                    <Col
                                        md={
                                            isExist?.data?.address_exists ==
                                            true
                                                ? 6
                                                : 7
                                        }
                                        className={
                                            isExist?.data?.address_exists ==
                                            true
                                                ? "d-none"
                                                : "d-block"
                                        }
                                    >
                                        <Row className="my-3">
                                            <Col lg={12}>
                                                {questAns == "Ja" ? (
                                                    postCodeCheck(postCode) ===
                                                        false &&
                                                    houseCheck(houseNo) ===
                                                        false ? (
                                                        <Button
                                                            type="submit"
                                                            className="bg-site-primary w-100 btnHeight border-none"
                                                            disabled={!questAns}
                                                        >
                                                            <Row className="align-items-center">
                                                                <Col
                                                                    md={9}
                                                                    lg={10}
                                                                >
                                                                    <span className="btnText text-center">
                                                                        Naar
                                                                        stap 2.
                                                                        Aanslaggegevens
                                                                    </span>
                                                                </Col>
                                                                <Col
                                                                    md={3}
                                                                    lg={2}
                                                                >
                                                                    <img
                                                                        src={
                                                                            arrowRight
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            type="button"
                                                            className="bg-site-primary w-100 btnHeight border-none"
                                                            disabled={!questAns}
                                                        >
                                                            <Row className="align-items-center">
                                                                <Col
                                                                    md={9}
                                                                    lg={10}
                                                                >
                                                                    <span className="btnText text-center">
                                                                        Naar
                                                                        stap 2.
                                                                        Aanslaggegevens
                                                                    </span>
                                                                </Col>
                                                                <Col
                                                                    md={3}
                                                                    lg={2}
                                                                >
                                                                    <img
                                                                        src={
                                                                            arrowRight
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Button>
                                                    )
                                                ) : (
                                                    <Button
                                                        type="submit"
                                                        className="bg-site-primary w-100 btnHeight border-none"
                                                        disabled={!questAns}
                                                    >
                                                        <Row className="align-items-center">
                                                            <Col md={9} lg={10}>
                                                                <span className="btnText text-center">
                                                                    Naar stap 2.
                                                                    Aanslaggegevens
                                                                </span>
                                                            </Col>
                                                            <Col md={3} lg={2}>
                                                                <img
                                                                    src={
                                                                        arrowRight
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                    {isExist?.data?.address_exists == true ? (
                                        <Col md={6}>
                                            <a
                                                href="https://mijn.bezwaarmaker.nl/login"
                                                className="btn bg-site-primary w-100 btnHeight border-none"
                                            >
                                                <span className="btnText text-center">
                                                    Dit adres lijkt bij ons al
                                                    bekend te zijn. Log in om
                                                    verder te gaan.
                                                </span>
                                            </a>
                                        </Col>
                                    ) : null}
                                </Row>
                            </Form>
                        </>
                    ) : isActiveTab == 2 ? (
                        <>
                            <Row className="mx-2 my-4">
                                <Col>
                                    <h4 className="formBodyHeading">
                                        Aanslaggegevens
                                    </h4>
                                </Col>
                            </Row>
                            <Form
                                onSubmit={(evt) => {
                                    evt.preventDefault();
                                    {
                                        // questAns == "Nee" && skipStep == true
                                        //     ? setIsActiveTab(4)
                                        //     :
                                        setIsActiveTab(3);
                                    }
                                }}
                            >
                                <Row className="mx-2">
                                    <Col md={7}>
                                        {/* {questAns == "Nee" ? (
                                            <>
                                                <p className="mb-2 radioHeading">
                                                    Heb jij je aanslag biljet
                                                    bij de hand?
                                                </p>
                                                <div className="d-flex align-items-center mb-4">
                                                    <div className="radioText mr-4">
                                                        <input
                                                            required
                                                            id="Ja"
                                                            type="radio"
                                                            checked={
                                                                questAns2 ==
                                                                "Ja"
                                                            }
                                                            name="same"
                                                            className="mr-2 radioBtn"
                                                            value="Ja"
                                                            onChange={(e) =>
                                                                handleQuest2Value(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            className="m-0 p-0 pointer"
                                                            for="Ja"
                                                        >
                                                            Ja
                                                        </label>
                                                    </div>
                                                    <div className="radioText">
                                                        <input
                                                            required
                                                            id="Nee"
                                                            type="radio"
                                                            checked={
                                                                questAns2 ==
                                                                "Nee"
                                                            }
                                                            name="same"
                                                            className="mr-2 radioBtn pt-5"
                                                            value="Nee"
                                                            onChange={(e) =>
                                                                handleQuest2Value(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            className="m-0 p-0 pointer"
                                                            for="Nee"
                                                        >
                                                            Nee
                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                        ) : null} */}

                                        <Row>
                                            <Col>
                                                <div className="d-flex justify-content-between align-items-center pb-2 px-md-2 px-0">
                                                    {/* <Label className="fontText mb-0 is-invalid invalid-feedback text-danger">
                                                        <b>Dagtekening</b>
                                                    </Label> */}
                                                    <Label className="fontText mb-0">
                                                        <b>Dagtekening</b>
                                                    </Label>
                                                    <div>
                                                        <i
                                                            href="#"
                                                            id="UncontrolledTooltipExample3"
                                                            className="fas fa-question-circle fa-lg"
                                                        ></i>
                                                        <UncontrolledTooltip
                                                            placement="right"
                                                            autohide={false}
                                                            target="UncontrolledTooltipExample3"
                                                        >
                                                            <div>
                                                                <span className="fontText">
                                                                    De
                                                                    dagtekening
                                                                    kunt u
                                                                    terugvinden
                                                                    op het
                                                                    aanslagbiljet
                                                                    gemeentelijke
                                                                    belastingen
                                                                </span>
                                                                <br />
                                                                <br />
                                                                <img
                                                                    src={
                                                                        tooltip2
                                                                    }
                                                                    className="img-responsive "
                                                                    width="50%"
                                                                />
                                                            </div>
                                                        </UncontrolledTooltip>
                                                    </div>
                                                </div>
                                                <Input
                                                    id="Dagtekening"
                                                    value={drawingDate}
                                                    invalid={isLessThan42Days(
                                                        drawingDate
                                                    )}
                                                    onChange={(e) =>
                                                        setDrawingDate(
                                                            e.target.value
                                                        )
                                                    }
                                                    max={moment().format(
                                                        "YYYY-MM-DD"
                                                    )}
                                                    placeholder="Dagtekening op aanslagbiljet"
                                                    type="date"
                                                    className="rounded-pill py-4 d-flex"
                                                    required={
                                                        questAns == "Nee" &&
                                                        skipStep == true
                                                            ? false
                                                            : true
                                                    }
                                                />

                                                <FormFeedback invalid>
                                                    Helaas, de bezwaartermijn
                                                    van 6 weken is verlopen.
                                                </FormFeedback>
                                                {minDate == true ? (
                                                    <p>
                                                        42 days before date you
                                                        selected
                                                    </p>
                                                ) : (
                                                    ""
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col>
                                                <div className="d-flex justify-content-between align-items-center pb-2 px-md-2 px-0">
                                                    <Label className="fontText mb-0">
                                                        <b>Vorderingsnummer</b>
                                                    </Label>
                                                    <i
                                                        href="#"
                                                        id="UncontrolledTooltipExample1"
                                                        className="fas fa-question-circle fa-lg"
                                                    ></i>
                                                    <UncontrolledTooltip
                                                        placement="right"
                                                        autohide={false}
                                                        target="UncontrolledTooltipExample1"
                                                    >
                                                        <div>
                                                            <span className="fontText">
                                                                Het
                                                                aanslagnummer
                                                                kunt u
                                                                terugvinden op
                                                                het
                                                                aanslagbiljet
                                                                gemeentelijke
                                                                belastingen.
                                                            </span>
                                                            <br />
                                                            <br />
                                                            <img
                                                                src={tooltip1}
                                                                className="img-responsive "
                                                                width="50%"
                                                            />
                                                        </div>
                                                    </UncontrolledTooltip>
                                                </div>
                                                <Input
                                                    id="Vorderingsnummer"
                                                    value={claimNumber}
                                                    invalid={claimNumberCheck()}
                                                    onChange={(e) =>
                                                        setClaimNumber(
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder="Vorderingsnummer"
                                                    type="text"
                                                    className="rounded-pill py-4"
                                                    required={
                                                        questAns == "Nee" &&
                                                        skipStep == true
                                                            ? false
                                                            : true
                                                    }
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mt-3 mb-3">
                                            <Col>
                                                <div className="d-flex justify-content-between align-items-center pb-2 px-md-2 px-0">
                                                    <Label className="fontText mb-0">
                                                        <b>WOZ-waarde</b>
                                                    </Label>
                                                    <i
                                                        href="#"
                                                        id="UncontrolledTooltipExample4"
                                                        className="fas fa-question-circle fa-lg"
                                                    ></i>
                                                    <UncontrolledTooltip
                                                        placement="right"
                                                        autohide={false}
                                                        target="UncontrolledTooltipExample4"
                                                    >
                                                        <div>
                                                            <span className="fontText">
                                                                De WOZ-waarde
                                                                kunt u
                                                                terugvinden op
                                                                het
                                                                aanslagbiljet
                                                                gemeentelijke
                                                                belastingen. De
                                                                WOZ-waarde is de
                                                                grondslag voor
                                                                de gemeentelijke
                                                                heffingen.
                                                            </span>
                                                            <br />
                                                            <br />
                                                            <img
                                                                src={tooltip3}
                                                                className="img-responsive "
                                                                width="50%"
                                                            />
                                                        </div>
                                                    </UncontrolledTooltip>
                                                </div>
                                                <div className="wozInput">
                                                    {wozValue.length > 0 ? (
                                                        <i className="fas fa-euro-sign euroSign"></i>
                                                    ) : (
                                                        ""
                                                    )}

                                                    <Input
                                                        id="WOZwaarde"
                                                        value={wozValue}
                                                        invalid={wozValueCheck()}
                                                        onChange={(e) =>
                                                            setWozValue(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="WOZ-waarde"
                                                        type="text"
                                                        className={
                                                            wozValue.length > 0
                                                                ? "rounded-pill py-4 pl-4"
                                                                : "rounded-pill py-4"
                                                        }
                                                        required={
                                                            questAns == "Nee" &&
                                                            skipStep == true
                                                                ? false
                                                                : true
                                                        }
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* <Row className="mt-3 mb-3">
                                            <Col>
                                                <div className="d-flex justify-content-between align-items-center pb-2 px-md-2 px-0">
                                                    <Label className="fontText mb-0">
                                                        <b>Waardepeildatum</b>
                                                    </Label>
                                                    <i
                                                        href="#"
                                                        id="UncontrolledTooltipExample5"
                                                        className="fas fa-question-circle fa-lg"
                                                    ></i>
                                                    <UncontrolledTooltip
                                                        placement="right"
                                                        autohide={false}
                                                        target="UncontrolledTooltipExample5"
                                                    >
                                                        <div>
                                                            <span className="fontText">
                                                                De
                                                                waardepeildatum
                                                                kunt u
                                                                terugvinden op
                                                                het
                                                                aanslagbiljet
                                                                gemeentelijke
                                                                belastingen. Dit
                                                                is de
                                                                taxatiedatum,
                                                                deze datum is
                                                                altijd 1 januari
                                                                van het vorige
                                                                jaar. De waarde
                                                                loopt één jaar
                                                                achter.
                                                            </span>
                                                            <br />
                                                            <br />
                                                            <img
                                                                src={tooltip4}
                                                                className="img-responsive "
                                                                width="50%"
                                                            />
                                                        </div>
                                                    </UncontrolledTooltip>
                                                </div>
                                                <Input
                                                    type="select"
                                                    value={refrenceDate}
                                                    onChange={(e) =>
                                                        setRefrenceDate(
                                                            e.target.value
                                                        )
                                                    }
                                                    bsSize="lg"
                                                    className="rounded-pill selectText "
                                                    id="exampleSelect"
                                                    required={
                                                        questAns == "Nee" &&
                                                        skipStep == true
                                                            ? false
                                                            : true
                                                    }
                                                >
                                                    <option value="" disabled>
                                                        Waardepeildatum
                                                    </option>
                                                    {reffrenceArray.map(
                                                        (ele) => {
                                                            return (
                                                                <option
                                                                    value={
                                                                        ele.value
                                                                    }
                                                                >
                                                                    {ele.name}
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </Input>
                                            </Col>
                                        </Row> */}
                                    </Col>
                                </Row>
                                <Row className="mx-2 align-items-center">
                                    <Col md={7}>
                                        <Row className="my-3">
                                            <Col
                                                lg={3}
                                                className=" p-0 order-2 order-lg-1 mt-lg-0 mt-4"
                                            >
                                                <button
                                                    onClick={() =>
                                                        setIsActiveTab(
                                                            isActiveTab - 1
                                                        )
                                                    }
                                                    className="btn bg-site-primary w-100 btnHeight border-none"
                                                    // disabled
                                                >
                                                    <span className="btnText text-center">
                                                        Vorige
                                                    </span>
                                                </button>
                                            </Col>
                                            <Col className=" p-0 px-md-2 order-1 order-lg-2">
                                                {questAns == "Ja" ||
                                                questAns == "Nee" ? (
                                                    wozValueCheck(wozValue) ===
                                                        false &&
                                                    claimNumberCheck(
                                                        claimNumber
                                                    ) === false ? (
                                                        <Button
                                                            type="submit"
                                                            className="bg-site-primary w-100 btnHeight border-0"
                                                            // disabled
                                                        >
                                                            <Row className="align-items-center">
                                                                <Col
                                                                    md={9}
                                                                    lg={10}
                                                                >
                                                                    <span className="btnText text-center">
                                                                        Naar
                                                                        stap 3.
                                                                        Reden
                                                                        bezwaar
                                                                    </span>
                                                                </Col>
                                                                <Col
                                                                    md={3}
                                                                    lg={2}
                                                                >
                                                                    <img
                                                                        src={
                                                                            arrowRight
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            type="button"
                                                            className="btn bg-site-primary w-100 btnHeight"
                                                            // disabled
                                                        >
                                                            <Row className="align-items-center">
                                                                <Col
                                                                    md={9}
                                                                    lg={10}
                                                                >
                                                                    <span className="btnText text-center">
                                                                        Naar
                                                                        stap 3.
                                                                        Reden
                                                                        bezwaar
                                                                    </span>
                                                                </Col>
                                                                <Col
                                                                    md={3}
                                                                    lg={2}
                                                                >
                                                                    <img
                                                                        src={
                                                                            arrowRight
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Button>
                                                    )
                                                ) : (
                                                    <Button
                                                        onClick={() =>
                                                            handleSkipStepChange(
                                                                false
                                                            )
                                                        }
                                                        type="submit"
                                                        className="bg-site-primary w-100 btnHeight border-0"
                                                        // disabled
                                                    >
                                                        <Row className="align-items-center">
                                                            <Col>
                                                                <span className="btnText text-center">
                                                                    Volgende
                                                                    stap
                                                                </span>
                                                            </Col>
                                                            {/* <Col md={3} lg={2}>
                                                                <img
                                                                    src={
                                                                        arrowRight
                                                                    }
                                                                />
                                                            </Col> */}
                                                        </Row>
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                    {/* {questAns == "Nee" ? (
                                        <Col>
                                            <Button
                                                onClick={() =>
                                                    handleSkipStepChange(true)
                                                }
                                                type="submit"
                                                className="w-100 bg-danger btnHeight border-none"
                                            >
                                                <Row className="align-items-center">
                                                    <Col>
                                                        <span className="btnText text-center">
                                                            Ik heb geen aanslag
                                                            biljet bij de hand
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Button>
                                        </Col>
                                    ) : null} */}
                                </Row>
                            </Form>
                        </>
                    ) : isActiveTab == 3 ? (
                        <>
                            <Row className="mx-2 mt-4">
                                <Col>
                                    <h4 className="formBodyHeading">
                                        Reden bezwaar
                                    </h4>
                                </Col>
                            </Row>
                            <Form
                                onSubmit={(evt) => {
                                    evt.preventDefault();
                                    setIsActiveTab(4);
                                }}
                            >
                                <Row className="mx-2">
                                    <Col>
                                        <Label className="fontText mb-0">
                                            <b>Toelichting</b>
                                        </Label>
                                        <Input
                                            className="rounded mt-3"
                                            placeholder="Geef ons een toelichting waarom er volgens u bezwaar gemaakt moet worden. "
                                            type="textarea"
                                            rows={10}
                                            value={description}
                                            onChange={(e) =>
                                                setDescription(e.target.value)
                                            }
                                            required={
                                                questAns2 == "Ja" ? true : false
                                            }
                                        />
                                        <p className="my-2">
                                            <i>
                                                U kunt deze toelichting later
                                                nog aanvullen
                                            </i>
                                        </p>
                                        <hr className="hrStyling" />
                                    </Col>
                                </Row>
                                <Row className="mx-2">
                                    <Col md={7}>
                                        <Row className="mb-3">
                                            <Col
                                                lg={3}
                                                className="p-0 order-2 order-lg-1 mt-lg-0 mt-4"
                                            >
                                                <Button
                                                    className="bg-site-primary w-100 btnHeight"
                                                    onClick={() => {
                                                        setIsActiveTab(
                                                            isActiveTab - 1
                                                        );
                                                        setQuestAns2("Ja");
                                                    }}
                                                    // disabled
                                                >
                                                    <span className="btnText text-center">
                                                        Vorige
                                                    </span>
                                                </Button>
                                            </Col>
                                            <Col className="order-1 order-lg-2  p-0 px-md-2">
                                                <Button
                                                    type="submit"
                                                    className="bg-site-primary w-100 btnHeight"
                                                >
                                                    <Row className="align-items-center">
                                                        <Col md={9} lg={10}>
                                                            <span className="btnText text-center">
                                                                Naar stap 4.
                                                                Contactgegevens
                                                            </span>
                                                        </Col>
                                                        <Col md={3} lg={2}>
                                                            <img
                                                                src={arrowRight}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    ) : isActiveTab == 4 ? (
                        <>
                            <>
                                <Row className="mx-2 my-4">
                                    <Col>
                                        <h4 className="formBodyHeading">
                                            Contactgegevens
                                        </h4>
                                        <h5 className="formBodyText pt-1">
                                            Neem de gegevens over van uw
                                            aanslagbiljet gemeentelijke
                                            belastingen.
                                        </h5>
                                    </Col>
                                </Row>
                                <Form
                                    onSubmit={(evt) => {
                                        evt.preventDefault();
                                        setIsActiveTab(5);
                                    }}
                                >
                                    <Row className="mx-2">
                                        <Col md={7}>
                                            <div className="d-flex justify-content-between align-items-center pt-1">
                                                <div>
                                                    <span className="formBodyText">
                                                        Naam van persoon op
                                                        aanslagbiljet
                                                    </span>
                                                </div>
                                                <div>
                                                    <i
                                                        href="#"
                                                        // id="UncontrolledTooltipExample2"
                                                        className="fas fa-exclamation-circle fa-lg pr-0 pr-md-1"
                                                    ></i>
                                                    {/* <UncontrolledTooltip
                                                        placement="right"
                                                        autohide={false}
                                                        target="UncontrolledTooltipExample2"
                                                    >
                                                        <div>
                                                            <span className="fontText">
                                                                De benaming die
                                                                u hier gaat
                                                                invullen moet
                                                            </span>
                                                            <br />
                                                            <span className="fontText">
                                                                <b>
                                                                    overeenkomen
                                                                    met uw
                                                                    aanslagformulier!
                                                                </b>
                                                            </span>
                                                        </div>
                                                    </UncontrolledTooltip> */}
                                                </div>
                                            </div>
                                            <Row className=" align-items-center mt-3">
                                                <Col>
                                                    <Label className="fontText mb-0 pb-2 px-0 px-md-2">
                                                        <b>
                                                            Voorletters op
                                                            aanslagbiljet
                                                        </b>
                                                    </Label>
                                                    <Input
                                                        name="Voorletters"
                                                        id="myField"
                                                        value={initials}
                                                        onChange={(e) => {
                                                            let inputValue =
                                                                e.target.value;
                                                            let fieldRef =
                                                                document.getElementById(
                                                                    "myField"
                                                                );
                                                            if (
                                                                inputValue.length <
                                                                initials.length
                                                            ) {
                                                                fieldRef.value =
                                                                    inputValue.slice(
                                                                        0,
                                                                        -1
                                                                    );
                                                                setInitials(
                                                                    inputValue
                                                                );
                                                            } else {
                                                                fieldRef.value =
                                                                    fieldRef.value +
                                                                    ".";
                                                                setInitials(
                                                                    fieldRef.value.toUpperCase()
                                                                );
                                                            }
                                                        }}
                                                        placeholder="Voorletters op aanslagbiljet"
                                                        type="text"
                                                        className="rounded-pill py-4"
                                                        required
                                                    />
                                                </Col>
                                                {/* <Col className="mt-3 mt-lg-0">
                                                    <Input
                                                        id="Tussenvoegsel"
                                                        value={infix}
                                                        onChange={(e) => {
                                                            setInfix(
                                                                e.target.value
                                                            );
                                                        }}
                                                        placeholder="Tussenvoegsel"
                                                        type="text"
                                                        className="rounded-pill py-4"
                                                    />
                                                </Col> */}
                                            </Row>
                                            <Row className="mt-3">
                                                <Col>
                                                    <Label className="fontText mb-0 pb-2 px-0 px-md-2">
                                                        <b>
                                                            Achternaam ор
                                                            aanslagbiljet
                                                        </b>
                                                    </Label>
                                                    <Input
                                                        id="Achternaam ор aanslagbiljet"
                                                        value={surname}
                                                        invalid={
                                                            surNameCheck() ||
                                                            error_response?.lastname
                                                        }
                                                        onChange={(e) => {
                                                            setSurname(
                                                                e.target.value
                                                            );
                                                            error_response?.lastname &&
                                                                dispatch(
                                                                    errorResponse()
                                                                );
                                                        }}
                                                        placeholder="Achternaam ор aanslagbiljet"
                                                        type="text"
                                                        className="rounded-pill py-4"
                                                        required
                                                    />
                                                    {error_response?.lastname && (
                                                        <div className="text-center">
                                                            <small className="text-danger">
                                                                Voornaam niet
                                                                correct
                                                            </small>
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col>
                                                    <Label className="fontText mb-0 pb-2 px-0 px-md-2">
                                                        <b>E-mailadres</b>
                                                    </Label>
                                                    <Input
                                                        id="E-mailadres"
                                                        value={email}
                                                        invalid={
                                                            emailCheck() ||
                                                            error_response?.email
                                                        }
                                                        onChange={(e) => {
                                                            setEmail(
                                                                e.target.value
                                                            );
                                                            error_response?.email &&
                                                                dispatch(
                                                                    errorResponse()
                                                                );
                                                        }}
                                                        placeholder="E-mailadres"
                                                        type="email"
                                                        className="rounded-pill py-4"
                                                        required
                                                    />
                                                    {error_response?.email && (
                                                        <div className="text-center">
                                                            <small className="text-danger">
                                                                E-mailadres niet
                                                                correct
                                                            </small>
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Input
                                                    style={{ opacity: "0.001" }}
                                                    type="checkbox"
                                                    value={bootCheck}
                                                    onChange={() =>
                                                        setBootCheck(!bootCheck)
                                                    }
                                                />
                                                <Col>
                                                    <Label className="fontText mb-0 pb-2 px-0 px-md-2">
                                                        <b>Telefoonnummer</b>
                                                    </Label>
                                                    <Input
                                                        id="Telefoonnummer"
                                                        value={phone}
                                                        invalid={
                                                            phoneCheck() ||
                                                            error_response?.phone_number
                                                        }
                                                        onChange={(e) => {
                                                            setPhone(
                                                                e.target.value
                                                            );
                                                            error_response?.phone_number &&
                                                                dispatch(
                                                                    errorResponse()
                                                                );
                                                        }}
                                                        placeholder="Telefoonnummer"
                                                        type="text"
                                                        className="rounded-pill py-4"
                                                        required
                                                    />
                                                    {error_response?.phone_number && (
                                                        <div className="text-center">
                                                            <small className="text-danger">
                                                                Telefoonnummer
                                                                niet correct
                                                            </small>
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={5}>
                                            <div className="box2 sb2">
                                                <span className="fontText">
                                                    De benaming die u hier gaat
                                                    invullen moet
                                                </span>
                                                <br />
                                                <span className="fontText">
                                                    <b>
                                                        overeenkomen met uw
                                                        aanslagformulier!
                                                    </b>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mx-2 py-2 align-items-center">
                                        <Col
                                            md={
                                                isExist?.data?.email_exists ==
                                                true
                                                    ? 6
                                                    : 7
                                            }
                                            className={
                                                isExist?.data?.email_exists ==
                                                true
                                                    ? "d-none"
                                                    : "d-block"
                                            }
                                        >
                                            <Row className="my-3">
                                                <Col
                                                    lg={3}
                                                    className="p-0 order-2 order-lg-1 mt-lg-0 mt-4"
                                                >
                                                    <Button
                                                        className="bg-site-primary w-100 btnHeight"
                                                        onClick={() =>
                                                            setIsActiveTab(
                                                                isActiveTab - 1
                                                            )
                                                        }
                                                        // disabled
                                                    >
                                                        <span className="btnText text-center">
                                                            Vorige
                                                        </span>
                                                    </Button>
                                                </Col>
                                                <Col className="order-1 order-lg-2 p-0 px-md-2">
                                                    {surNameCheck(surname) ===
                                                        false &&
                                                    emailCheck(email) ===
                                                        false &&
                                                    phoneCheck(phone) ===
                                                        false ? (
                                                        <Button
                                                            type="submit"
                                                            className="bg-site-primary w-100 btnHeight"
                                                            // disabled
                                                        >
                                                            <Row className="align-items-center">
                                                                <Col
                                                                    md={9}
                                                                    lg={10}
                                                                >
                                                                    <span className="btnText text-center">
                                                                        Naar
                                                                        stap 5.
                                                                        Afronden
                                                                    </span>
                                                                </Col>
                                                                <Col
                                                                    md={3}
                                                                    lg={2}
                                                                >
                                                                    <img
                                                                        src={
                                                                            arrowRight
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            type="button"
                                                            className="bg-site-primary w-100 btnHeight"
                                                            // disabled
                                                        >
                                                            <Row className="align-items-center">
                                                                <Col
                                                                    md={9}
                                                                    lg={10}
                                                                >
                                                                    <span className="btnText text-center">
                                                                        Naar
                                                                        stap 5.
                                                                        Afronden
                                                                    </span>
                                                                </Col>
                                                                <Col
                                                                    md={3}
                                                                    lg={2}
                                                                >
                                                                    <img
                                                                        src={
                                                                            arrowRight
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                        {isExist?.data?.email_exists == true ? (
                                            <Col md={6}>
                                                <a
                                                    href="https://mijn.bezwaarmaker.nl/login"
                                                    className=" btn bg-site-primary w-100 btnHeight text-white"
                                                >
                                                    <span className=" text-center">
                                                        Dit adres lijkt bij ons
                                                        al bekend te zijn. Log
                                                        in om verder te gaan.
                                                    </span>
                                                </a>
                                            </Col>
                                        ) : null}
                                    </Row>
                                </Form>
                            </>
                        </>
                    ) : (
                        <>
                            <Row className="mx-2 my-4">
                                <Col>
                                    <h3 className="formBodyHeading">
                                        Controle en afronding
                                    </h3>
                                    <h5 className="formBodyText">
                                        Controleer onderstaande gegevens nog
                                        eenmaal goed.
                                    </h5>
                                </Col>
                            </Row>
                            <Form onSubmit={handleData}>
                                <h5 className="formHeading2 mx-4 mb-3 fontText">
                                    Jouw gegevens
                                </h5>

                                <Row className="mx-2">
                                    <Col md={4}>
                                        <p className="fontText mb-0">
                                            Locatie:
                                        </p>
                                    </Col>
                                    <Col md={8}>
                                        <p className="fontText mb-0">
                                            <b>
                                                {address?.street}{" "}
                                                {address?.house_number
                                                    ? address?.house_number
                                                    : address?.streetNumber}
                                            </b>
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="mx-2 pt-1">
                                    <Col md={4}>
                                        <p className="fontText mb-0">
                                            Postcode en plaats:
                                        </p>
                                    </Col>
                                    <Col md={8}>
                                        <p className="fontText mb-0">
                                            <b>
                                                {address?.postcode
                                                    ? address?.postcode
                                                    : address?.postalCode}{" "}
                                                {address?.city
                                                    ? address?.city
                                                    : address?.municipality}
                                            </b>
                                        </p>
                                    </Col>
                                </Row>
                                <h5 className="formHeading2 mx-4 my-3 fontText">
                                    Contactgegevens
                                </h5>

                                <Row className="mx-2">
                                    <Col md={4}>
                                        <p className="fontText mb-0">
                                            E-mailadres:
                                        </p>
                                    </Col>
                                    <Col md={8}>
                                        <p className="fontText mb-0">
                                            <b>{email}</b>
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="mx-2 pt-1">
                                    <Col md={4}>
                                        <p className="fontText mb-0">
                                            Telefoonnummer:
                                        </p>
                                    </Col>
                                    <Col md={8}>
                                        <p className="fontText mb-0">
                                            <b>{phone}</b>
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="mx-2 align-items-center my-2">
                                    <Col md={7}>
                                        <div className="align-items-center d-flex justify-content-between">
                                            <h5 className="formHeading2 fontText">
                                                Aanslag gegevens
                                            </h5>
                                            <div>
                                                {/* <i
                                                    // href="#"
                                                    // id="UncontrolledTooltipExample2"
                                                    className="fas fa-exclamation-circle fa-lg pr-0 pr-md-3"
                                                ></i> */}
                                                {/* <UncontrolledTooltip
                                                    placement="right"
                                                    autohide={false}
                                                    target="UncontrolledTooltipExample2"
                                                >
                                                    <div>
                                                        <span className="fontText">
                                                            Controleer deze{" "}
                                                            <b>dagtekening</b>{" "}
                                                            goed. Omeen te laat
                                                            ingediend
                                                            bezwaarschift te
                                                            voorkomen.
                                                        </span>
                                                    </div>
                                                </UncontrolledTooltip> */}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mx-2">
                                    <Col md={7}>
                                        <Row>
                                            <Col md={7}>
                                                <p className="fontText mb-0">
                                                    Dagtekening:
                                                </p>
                                            </Col>
                                            <Col
                                                md={5}
                                                className="d-flex justify-content-between"
                                            >
                                                <p className="fontText mb-0 ">
                                                    <b>{drawingDate}</b>
                                                </p>
                                                <i className="fas fa-exclamation-circle fa-lg"></i>
                                            </Col>
                                        </Row>
                                        {questAns2 == "Nee" ? null : (
                                            <Row className="pt-1">
                                                <Col md={7}>
                                                    <p className="fontText mb-0">
                                                        Vorderingsnummer:
                                                    </p>
                                                </Col>
                                                <Col md={5}>
                                                    <p className="fontText mb-0">
                                                        <b>{claimNumber}</b>
                                                    </p>
                                                </Col>
                                            </Row>
                                        )}

                                        {questAns2 == "Nee" ? null : (
                                            <Row className="pt-1">
                                                <Col md={7}>
                                                    <p className="fontText mb-0">
                                                        WOZ-waarde:
                                                    </p>
                                                </Col>
                                                <Col md={5}>
                                                    <p className="fontText mb-0">
                                                        <b>
                                                            €{" "}
                                                            {parseInt(
                                                                wozValue.replace(
                                                                    ".",
                                                                    ""
                                                                )
                                                            )}
                                                        </b>
                                                    </p>
                                                </Col>
                                            </Row>
                                        )}

                                        <Row className="pt-1">
                                            <Col md={7}>
                                                <p className="fontText mb-0">
                                                    Naam belastingplichtige
                                                </p>
                                            </Col>
                                            <Col md={5}>
                                                <p className="fontText mb-0">
                                                    <b>
                                                        {initials} {surname}
                                                    </b>
                                                </p>
                                            </Col>
                                        </Row>
                                        {/* {questAns2 == "Nee" ? null : (
                                            <Row className="pt-1">
                                                <Col md={7}>
                                                    <p className="fontText mb-0">
                                                        Waardepeildatum:
                                                    </p>
                                                </Col>
                                                <Col md={5}>
                                                    <p className="fontText mb-0">
                                                        <b>
                                                            {
                                                                getReference(
                                                                    refrenceDate
                                                                )?.name
                                                            }
                                                        </b>
                                                    </p>
                                                </Col>
                                            </Row>
                                        )} */}
                                    </Col>
                                    <Col md={5}>
                                        <div className="box2 sb2">
                                            <p className="fontText mb-0">
                                                Controleer deze{" "}
                                                <b>dagtekening</b> goed om een
                                                te laat ingediend bezwaarschrift
                                                te voorkomen
                                            </p>
                                        </div>
                                    </Col>
                                </Row>

                                <div className="mx-4">
                                    <hr
                                        style={{
                                            border: "1px solid #ced4da",
                                        }}
                                    />
                                </div>
                                <div className="d-flex justify-content-end mx-4">
                                    <span className="fontText">
                                        Klopt er iets niet?{" "}
                                        <b>
                                            <a
                                                className="pointer text-dark"
                                                onClick={() =>
                                                    setIsActiveTab(1)
                                                }
                                            >
                                                Aanpassen
                                            </a>
                                        </b>
                                    </span>
                                </div>
                                <div className="mx-4">
                                    <h3 className="fontText mt-2 mobileFont">
                                        Ondertekenen
                                    </h3>
                                    {/* <input
                                        type="radio"
                                        value="true"
                                        id="no"
                                        onChange={(e) =>
                                            handleSignatureInput(e.target.value)
                                        }
                                        name="same"
                                        checked={signature == "true"}
                                    />
                                    <label
                                        for="no"
                                        className="fontText ml-2 my-0 py-0 pointer"
                                    >
                                        Digitaal ondertekenen (snel en veilig)
                                    </label> */}
                                    {/* <br />
                                    <input
                                        type="radio"
                                        id="yes"
                                        value="false"
                                        onChange={(e) =>
                                            handleSignatureInput(e.target.value)
                                        }
                                        name="same"
                                        className="mt-3"
                                        checked={signature == "false"}
                                    />
                                    <label
                                        for="yes"
                                        className="fontText ml-2  my-0 py-0 pointer"
                                    >
                                        Per email ontvangen, printen,
                                        ondertekenen en terugsturen
                                    </label> */}
                                    <br />
                                    <br />
                                    <p className="fontText">
                                        Zet hieronder uw handtekening om ons
                                        toestemming te geven om namens u WOZ
                                        bezwaar te maken. Onze dienst is voor u
                                        <b> altijd gratis.</b> Probeer met uw
                                        muis zo goed mogelijk uw handtekening na
                                        te maken. Gebruik het tekenvlak{" "}
                                        <b>zo groot mogelijk.</b> Uw
                                        handtekening hoeft niet perfect te zijn.
                                    </p>
                                    <br />
                                    {signature == "true" ? (
                                        <>
                                            <SignaturePad
                                                ref={signaturePad}
                                                penColor="blue"
                                                backgroundColor="white"
                                                // clearButton="true"
                                            />

                                            {/* <Button className="bg-site-primary mr-1">
                                                <h3 className="btnText text-center">
                                                    Clear
                                                </h3>
                                            </Button>

                                            <Button
                                                className="bg-site-primary"
                                                onClick={saveSignature}
                                            >
                                                <h3 className="btnText text-center">
                                                    Save Signature
                                                </h3>
                                            </Button> */}
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    <div className="d-flex justify-content-between mt-2 fontText">
                                        <div>
                                            <span>
                                                U ondertekent hiermee de
                                                machtiging.
                                            </span>
                                        </div>
                                        <div
                                            onClick={clearSignature}
                                            className="pointer"
                                        >
                                            <span>Handtekening wissen</span>
                                        </div>
                                    </div>
                                </div>
                                <Row className="mx-2">
                                    <Col md={7}>
                                        <Row className="my-3">
                                            <Col
                                                lg={3}
                                                className="p-0 order-2 order-lg-1 mt-lg-0 mt-4"
                                            >
                                                <Button
                                                    className="bg-site-primary w-100 btnHeight"
                                                    onClick={() =>
                                                        setIsActiveTab(
                                                            isActiveTab - 1
                                                        )
                                                    }
                                                    // disabled
                                                >
                                                    <span className="btnText text-center">
                                                        Vorige
                                                    </span>
                                                </Button>
                                            </Col>
                                            <Col className="order-1 order-lg-2  p-0 px-md-2">
                                                <Button
                                                    className="bg-site-primary w-100 btnHeight"
                                                    // disabled
                                                >
                                                    <Row className="align-items-center">
                                                        <Col md={9} lg={10}>
                                                            {loading ? (
                                                                <Spinner />
                                                            ) : (
                                                                <span className="btnText text-center">
                                                                    Bezwaar
                                                                    indienen
                                                                </span>
                                                            )}
                                                        </Col>
                                                        {loading ? (
                                                            ""
                                                        ) : (
                                                            <Col md={3} lg={2}>
                                                                <img
                                                                    src={
                                                                        arrowRight
                                                                    }
                                                                />
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    )}
                </div>
            </Container>
        </div>
    );
};

export default RegForm;
